import React from 'react'
import { CFText, CFView, DefaultScreen, MobileScreen } from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import { hero, mobileHero } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="95vh"
          w="100%"
          image={`url(${mobileHero}) bottom / cover no-repeat`}
          boxShadow="1px 1px 3px rgba(0,0,0,.6)"
          column
          alignCenter
          justifyBetween
        >
          <Header />
          <CFView column center bg="rgba(0,0,0,.75)" w="100%">
            <CFView mt="10px" mb="5px" pulsate textCenter w="100%">
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="100vh"
          maxHeight="690px"
          w="100%"
          image={`url(${hero}) center / cover no-repeat`}
          style={{ backgroundAttachment: 'fixed' }}
          zIndex={90}
          column
          justifyBetween
        >
          <Header />
          <CFView row center bg="rgba(0,0,0,.75)" pv="7px">
            <CFText white xBold style={{ fontSize: 33 }} raleway>
              NOW TAKING ONLINE ORDERS!
            </CFText>
            <CFView pulsate ml="25px" mt="4px">
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
