import React from 'react'
import { Parallax } from 'react-parallax'
import { CFImage, CFView, DefaultScreen, MobileScreen } from 'components'
import { about, mobileAbout, aboutDivider } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView w="100%">
          <CFImage style="display: block;" src={mobileAbout} w="100%" alt="About" />
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFImage style="display: block;" src={about} w="100%" alt="About" />
      </DefaultScreen>
    </CFView>
  )
}
